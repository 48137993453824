/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { delay, finalize, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToasterService } from "../../services/toaster.service";
import { responseStatus } from "../constants/responseConst";
import { DialogServiceWrapper } from "src/app/services/dialog.service";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class GetInterceptor implements HttpInterceptor {
  private recentErrors = new Set<string>();
  private errorTimeout = 2000;
  constructor(
    private router: Router,
    private _toastrService: ToasterService,
    private ngxLoader: NgxUiLoaderService,
    private dialogWrapper: DialogServiceWrapper,
    private _authService: AuthService

  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxLoader.start();
    return next.handle(req).pipe(
      delay(0),
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.handleResponse(event.body, req.method);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.handleError(error);
        },
      }),
      finalize(() => {
        this.ngxLoader.stop();
      })
    );
  }

  private handleResponse(body: any, method: string): void {
    if (body?.statusCode) {
      switch (body.statusCode) {
        case responseStatus.SUCCESS:
          if (method === "POST" || method === "PUT" || method === 'PATCH') {
            if (body.message !="" && body.message != undefined && body.message != null) {
              this._toastrService.showSuccess(
                body.message
              );
            }
          }
          break;
        case responseStatus.BADREQUEST:
        case responseStatus.UNAUTHORIZED:
        case responseStatus.FORBIDDEN:
        case responseStatus.REQUESTTIMEOUT:
        case responseStatus.INTERNALSERVERERROR:
          this._toastrService.showError(body.message);
          if (
            body.statusCode === responseStatus.UNAUTHORIZED ||
            body.statusCode === responseStatus.FORBIDDEN
          ) {
            this.dialogWrapper.closeDialog();
          }
          break;
        default:
          break;
      }
    }
  }

  private handleError(error: HttpErrorResponse): void {
    const errorMessage =  error?.error?.message;

    if (errorMessage === "SomethingWentWrong") {
      return;
    }

    if (!this.recentErrors.has(errorMessage)) {
      this.recentErrors.add(errorMessage);
      this._toastrService.showError(errorMessage);
      setTimeout(
        () => this.recentErrors.delete(errorMessage),
        this.errorTimeout
      );
    }

    if (
      error.status === responseStatus.UNAUTHORIZED ||
      error.status === responseStatus.FORBIDDEN
    ) {
      localStorage.clear();
      this.dialogWrapper.closeDialog();
      this._authService.profileDetails.next(null);
      localStorage.setItem("language", 'en');
      this.router.navigateByUrl('/auth/login');
    }
  }
}
