import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  signal,
  ViewChild,
} from "@angular/core";
import { langugaes } from "../../../models/common";
import { ConfirmationService } from "primeng/api";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CommonModule, DOCUMENT } from "@angular/common";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AuthService } from "src/app/services/auth.service";
import { profileStatus } from "src/app/models/profileStatus";
import { DialogModule } from "primeng/dialog";
import { InputSwitchModule } from "primeng/inputswitch";
import { FormsModule } from "@angular/forms";
import { BaseClass } from "../../class/baseClass";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/models/auth";
import { environment } from "src/environments/environment";
import { InitialService } from "src/app/services/initial.service";
import { ConfirmDialogService } from "src/app/services/confirm-dialog.service";
import { assets } from "src/app/core/constants/assets.const";
import { EncryptionService } from "src/app/services/encryption.service";
import { NotificationService } from "src/app/services/notification.service";
import { notifications, notificationsResponse } from "src/app/models/notification.model";
import { MessagingService } from "src/app/services/messaging.service";

@Component({
  selector: "logibids-user-headers",
  templateUrl: "./user-headers.component.html",
  styleUrls: ["./user-headers.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    InfiniteScrollModule,
    DialogModule,
    InputSwitchModule,
    FormsModule,
  ],
  providers: [ConfirmationService],
})
export class UserHeadersComponent
  extends BaseClass
  implements OnInit, OnDestroy, AfterViewInit
{
  dropdown: boolean;
  language: boolean;
  privacy: boolean;
  user: any = null;
  page = 1;
  checked = true;
  is_reqto_driver = false;
  pendingDisplay = false;
  rejectedDisplay = false;
  private subscriptionSubject$: Subscription;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  languages = this.initialService.languages;
  filteredLanguages: langugaes[] = [];
  assetsConst = assets;
  @ViewChild('op1') overlayPanel1!: OverlayPanel;
  @ViewChild('op') overlayPanel!: OverlayPanel;
  @ViewChild('op2') overlayPanel2!: OverlayPanel;
  @ViewChild('op3') overlayPanel3!: OverlayPanel;
  selectedLang: any;
  protected size = signal<number>(10);
  protected from = signal<number>(1);
  protected total = signal<number>(0);
  notifications = signal<notifications[]>([]);
  @ViewChild('notificationButton', { read: ElementRef }) notificationButton!: ElementRef;
  notification = false;
  constructor(
    private _authService: AuthService,
    private injector: Injector,
    private initialService: InitialService,
    private confirmationService: ConfirmDialogService,
    private translate: TranslateService,
    private _EncryptionService: EncryptionService,
    private _NotificationService: NotificationService,
    private _messagingService: MessagingService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
    const savedLang = localStorage.getItem('language') || 'en';
    this.translateService.setDefaultLang(savedLang);
    this.translateService.use(savedLang);
    this.setDirection(savedLang);
    effect(() => {
      if (this.languages() && this.languages().length > 0) {
        this.filteredLanguages = this.languages();
      }
    });
  }

  ngOnInit() {
    this.selectedLang = localStorage['language'];
    this.subscriptionSubject$ = this._authService.profileDetails
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (res: User | null) => {
        if (res) {
          this.getUser();
    this.getNotifications();

        } else {
          if (localStorage[environment.storageKey]) {
            this.getUser();
            this.getNotifications();
          } else {
            this.user = null;
            this.hideOP();
          }
        }
      }, error: () => {
        if (localStorage[environment.storageKey]) {
          this.getUser();
          this.getNotifications();
        } else {
          this.hideOP();
        }
      },
    });
  }

  ngAfterViewInit() {
    this._messagingService.currentMessage.subscribe((data : Array<{id: number, user_id: string}>) => {
      if (data) {
        const notificationData = data;
        const result =  notificationData.find(item => item.user_id === this.user.id);
        if (result.id) {
          this.updateNotification(result.id);
        }
      }
    });
    this.route.queryParamMap.subscribe((params) => {
      const notification = params.get('notification');
      if (notification === 'true') {
        setTimeout(() => {
          this.openOverlay();
          this.removeQueryParam();
        }, 0); // Delay to ensure ViewChild is initialized
      }
    });
  }

  removeQueryParam() {
    this.router.navigate([], {
      queryParams: { notification: null }, // Removes the notification param
      queryParamsHandling: 'merge'
    });
  }

  openOverlay() {
    setTimeout(() => {  // Ensures ViewChild is properly available
      if (this.overlayPanel && this.notificationButton) {
        const event = new MouseEvent('click', { bubbles: true });
        this.overlayPanel.show(event, this.notificationButton.nativeElement);
      }
    }, 100); // Small delay to ensure ViewChild is initialized
  }

  getNotifications(): void {
    const query = `from=${this.from()}&size=${this.size()}`;
    this._NotificationService.getNotifications(query).pipe(takeUntil(this.destroy$)).subscribe((res: notificationsResponse) => {
      const newItems = res.list;
      this.notifications.set([...this.notifications(), ...newItems]);
      this.total.set(res.total);
      this.from.set(this.from() + 1);
    });
  }

  updateNotification(notifiId: number): void {
    this._NotificationService.updateNotificationById(notifiId).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.from.set(1);
      this.notifications.set([]);
      this.getNotifications();
      this.getUser();
    });
  }

  onScrollingFinished(): void {
    if (this.notifications().length >= this.total()) return;
    this.getNotifications();
  }

  btn_routeNotification(type: string, id: number, notifiId: number, vehicle_id: number): void {
    this.hideOP();
    switch(type) {
      case 'ENQUIRY': {
        this.router.navigateByUrl('/sp-bids', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/sp-bids/sp-new-bid/'+id);
        });
        break;
      }
      case 'TRIP': {
        this.router.navigateByUrl('/sp-bids', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/sp-bids/sp-new-bid/'+id);
        });
        break;
      }
      case 'PROFILE': {
        this.router.navigateByUrl('/sp-profile', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/sp-profile/profile');
        });
        break;
      }
      case 'VEHICLE': {
        this.router.navigateByUrl('/sp', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/sp/edit-vehicle?id'+vehicle_id);
        });
        break;
      }
    }
    this.updateNotification(notifiId);
  }

  hideOP(): void{
    if (this.overlayPanel1) {
      this.overlayPanel1.hide(); // Hide the overlay panel
    }
    if (this.overlayPanel2) {
      this.overlayPanel2.hide(); // Hide the overlay panel
    }
    if (this.overlayPanel3) {
      this.overlayPanel3.hide(); // Hide the overlay panel
    }
    if (this.overlayPanel) {
      this.overlayPanel.hide(); // Hide the overlay panel
    }
    
  }

  getUser() {
    this._authService.userProfile().subscribe((user: any) => {
      this.user = user;
      this.checked = this.user ? this.user.active : false;
      this.is_reqto_driver = this.user ? this.user.is_reqto_driver : false;
      this.notification = this.user ? this.user.is_reqto_driver : false;

      this.checkerOfProfileStatus();
    });
  }

  checkerOfProfileStatus = (): void => {
    if (this.user.hasOwnProperty("status")) {
      if (
        this.user.status === profileStatus.Pending &&
        this.user.hasOwnProperty("companyIdPhoto") &&
        this.user.hasOwnProperty("companyLicensePhoto")
      ) {
        this.pendingDisplay = true;
      } else if (
        this.user.status === profileStatus.Rejected &&
        this.user.hasOwnProperty("companyIdPhoto") &&
        this.user.hasOwnProperty("companyLicensePhoto")
      ) {
        this.rejectedDisplay = true;
      }
    }
  };

  showNav() {
    this.dropdown = !this.dropdown;
    this.language = false;
    this.privacy = false;
    this.hideOP();
  }

  showLang() {
    this.language = !this.language;
    this.dropdown = false;
    this.privacy = false;
    this.hideOP();

  }

  showPrivacy() {
    this.privacy = !this.privacy;
    this.language = false;
    this.dropdown = false;
    this.hideOP();
  }

  gotoPrivacy() {
    this.router.navigate(["/public/web/PRIVACY_POLICY"]);
    this.hideOP();

  }

  gotoAboutUs(): void {
    this.router.navigate(["/public/web/ABOUT_US_SP"]);
    this.hideOP();

  }

  gotoTerms() {
    this.router.navigate(["/public/web/TERMS_CONDITIONS"]);
    this.hideOP();

  }

  gotoFaq() {
    this.router.navigate(["/public/faq"]);
    this.hideOP();

  }

  gotoContact() {
    this.router.navigate(["/public/contact"]);
    this.hideOP();

  }

  gotoContract() {
    this.router.navigate(["/public/web/LEGAL_CONTRACT"]);
    this.hideOP();

  }

  gotoChangePassword() {
    this.router.navigate(["/my-profile/change-password"], {
      queryParams: { phoneNumber: this.user.phone_number },
    });
    this.hideOP();

  }

  btn_logOut(): void {
    this.confirmationService
      .showConfirmationDialog(
        ' ',
        this.translate.instant('SureLogout')
      )
      .then(result => {
        if (result) {
            this.hideOP();
          this.user = null;
          let currLang = localStorage.getItem("language");
          localStorage.clear();
          localStorage.setItem("language", currLang);
          this.router.navigateByUrl('/auth/login');
        }
      });
  }

  setLanguage(language: string) {
    this.selectedLang = language;
    localStorage.setItem("language", language);
    this.translateService.use(language);
    this.translateService.setDefaultLang(language);
    location.reload();
    this.hideOP();
  }

  private setDirection(lang: string) {
    const dir = lang === 'ar' ? 'rtl' : 'ltr';

    // Set the direction and language attributes on <html>
    this.renderer.setAttribute(this.document.documentElement, 'dir', dir);
    this.renderer.setAttribute(this.document.documentElement, 'lang', lang);

    // Optional: Add RTL class to body for additional styling
    if (dir === 'rtl') {
      this.renderer.addClass(this.document.body, 'rtl');
    } else {
      this.renderer.removeClass(this.document.body, 'rtl');
    }
  }

  gotoMyProfile() {
    this.router.navigate(["/my-profile"]);
    this.hideOP();

  }

  gotoMyPreferences() {
    this.router.navigate(["/sp-profile/profile-docs"]);
    this.hideOP();

  }

  gotoMyDrivers() {
    this.router.navigate(["/sp/my-drivers"]);
    this.hideOP();

  }

  gotoMyVehicles() {
    this.router.navigate(["/sp/my-vehicles"]);
    this.hideOP();

  }

  goToMyWallet() {
    this.router.navigate(["/sp/wallet"]);
    this.hideOP();

  }

  gotoMyTrips() {
    this.router.navigate(["/sp/my-trips"]);
    this.hideOP();

  }

  gotoHome() {
    if (this.user.type =="DRIVER") {
      this.router.navigate(["/sp/my-trips"]);
    } else {
      this.router.navigate(["/sp-bids/sp-auctions"]);
    }
    this.hideOP();

  }

  gotoMyRating() {
    this.router.navigate(["/sp/my-ratings"]);
    this.hideOP();

  }

  
  gotoMyEnqueires() {
    this.router.navigate(["/sp-bids/sp-auctions"]);
    this.hideOP();

  }

  deleteUser() {
    this.confirmationService
        .showConfirmationDialog(
          ' ',
          this.translate.instant('deleteAccountBody')
        )
        .then(result => {
          if (result) {
            this._authService.deleteAccount().pipe(takeUntil(this.destroy$)).subscribe(
              (data: any) => {
                this.hideOP();
                this.user = null;
                let currLang = localStorage.getItem("language");
                localStorage.clear();
                localStorage.setItem("language", currLang);
                this.router.navigateByUrl('/auth/login');
              }
            );
          }
        });
   
  }

  toggleStatus(event: any) {
    const payload: User ={
      active: event.checked
    } as User
    this._authService.updateProfile(payload).subscribe((data: User) => {
      this.user.active = event.checked;
      this._EncryptionService.encryptLocalStorage(data, "profile");
    });
  }

  toggleDriverStatus(event: any) {
    const payload: User = {
      is_reqto_driver: event.checked,
    } as User;
    this._authService.updateProfile(payload).subscribe((resp: any) => {
      this.user.is_reqto_driver = event.checked;
      this._EncryptionService.encryptLocalStorage(resp, "profile");
    });
  }

  notificationOnOff(event: any) {
    const payload: User = {
      notification: event.checked,
    } as User;
    this._authService.updateProfile(payload).subscribe((resp: any) => {
      this.user.notification = event.checked;
      this._EncryptionService.encryptLocalStorage(resp, "profile");
    });
  }

  getInfo(item: any) {
    let cat = item.payload.category;
    let enqId = item.payload.enquiryId;
    const language = localStorage.getItem("language") || "en";
    if (item.payload.pushType == 8) {
      this.router
        .navigate(["/sp/go-trips"], {
          queryParams: {
            type: item.payload.category,
            id: item.payload.enquiryId,
          },
        })
        .then(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/sp/go-trips"], {
                queryParams: {
                  type: item.payload.category,
                  id: item.payload.enquiryId,
                },
              });
            });
        });
    } else {
      if (
        item.payload.category == "DELIVERY" &&
        this.user.type === "SERVICE_PROVIDER"
      ) {
        this.router
          .navigate(["/sp-bids/sp-new-delivery"], {
            queryParams: { lang: language, id: enqId, type: cat },
          })
          .then(() => {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/sp-bids/sp-new-delivery"], {
                  queryParams: { lang: language, id: enqId, type: cat },
                });
              });
          });
      } else if (this.user.type === "SERVICE_PROVIDER") {
        this.router
          .navigate(["/sp-bids/sp-new-bid"], {
            queryParams: { id: enqId, type: cat, lang: language },
          })
          .then(() => {
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/sp-bids/sp-new-bid"], {
                  queryParams: { id: enqId, type: cat, lang: language },
                });
              });
          });
      } else {
        this.router.navigate(["/sp/my-trips"]);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.subscriptionSubject$.unsubscribe();
  }
}
