import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UserHeadersComponent } from "../../shared/components/headers/user-headers.component";

@Component({
  selector: 'logibids-main',
  standalone: true,
  imports: [RouterOutlet, UserHeadersComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
 


}
