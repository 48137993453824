import { ChangeDetectorRef, Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EncryptionService } from 'src/app/services/encryption.service';


@Injectable({
  providedIn: 'root'
})
export class premissionService  {
  constructor(private router: Router, private _authService:AuthService, private _EncryptionService: EncryptionService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const profile = this._EncryptionService.decryptLocalStorage('profile');
    const lang = localStorage.getItem('language');
     if (this._authService.isAuthenticated() && (profile && (profile?.iscontract == false && profile.type === 'SERVICE_PROVIDER'))) {
      localStorage.clear();
      localStorage.setItem('language', lang || 'en');
      this.router.navigate(['/auth/login']);
      return false;
    } else if (!this._authService.isAuthenticated()) {
      localStorage.clear();
      localStorage.setItem('language', lang || 'en');
      this.router.navigate(['/auth/login']);
      return false;
    } else {
      return true;
    }
  }
}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(premissionService).canActivate(next, state);
};

